<template>
  <div>
    <div>
      <t-dropdown variant="smActions">
        <template
          #trigger="{
                        mousedownHandler,
                        focusHandler,
                        blurHandler,
                        keydownHandler,
                    }"
        >
          <button
            aria-label="Rent Status Dropdown"
            :class="getRentalStatusClass"
            style="height: 35px; width: 170px"
            aria-haspopup="true"
            @mousedown="mousedownHandler"
            @focus="focusHandler"
            @blur="blurHandler"
            @keydown="keydownHandler"
          >
            <span>{{ getRentalStatusText(status) }}</span>
            <i
              v-if="!isBothRefunded && rawData.status === 'TRIP_COMPLETED'"
              class="fas fa-chevron-down"
            />
          </button>
        </template>

        <template #default="{ hide }">
          <div @click="hide()" class="py-1 mt-1 bg-white rounded-md shadow-md">
            <t-dropdown-item
              @click="$emit('rental-refund')"
              v-if="
                rawData.status === 'TRIP_COMPLETED' &&
                  rawData.invoice &&
                  !rawData.invoice.is_refunded
              "
              class="block px-3 py-1.5 cursor-pointer text-oOrange hover:bg-orange-100"
            >
              Rental Fee Refund
            </t-dropdown-item>
            <t-dropdown-item
              @click="$emit('extension-refund')"
              v-if="
                rawData.status === 'TRIP_COMPLETED' &&
                  rawData.rent_time_extension_invoice &&
                  !rawData.rent_time_extension_invoice.is_refunded
              "
              class="block px-3 py-1.5 cursor-pointer text-oOrange hover:bg-orange-100"
            >
              Extension Fee Refund
            </t-dropdown-item>
          </div>
        </template>
      </t-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatusPillButton',
  components: {},
  props: {
    status: {
      type: String,
      required: true,
      validator(val) {
        let whitelist = [
          'REQUESTED',
          'ACCEPTED',
          'REJECTED',
          'CANCELLED',
          'TRIP_COMPLETED',
          'DELIVERED',
        ]
        return whitelist.includes(val)
      },
    },
    rawData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      colors: {
        REQUESTED: 'orange',
        ACCEPTED: 'blue',
        REJECTED: 'blush',
        CANCELLED: 'red',
        TRIP_COMPLETED: 'green',
        DELIVERED: 'purple',
      },
      texts: {
        REQUESTED: 'Pending',
        ACCEPTED: 'Accepted',
        REJECTED: 'Rejected',
        CANCELLED: 'Cancelled',
        TRIP_COMPLETED: 'Completed',
        DELIVERED: 'On Rent',
      },
    }
  },
  computed: {
    getRentalStatusClass() {
      const color = this.getRentalStatusColor(this.status)
      return `rental-status-pill-button ${color}`
    },
    isBothRefunded() {
      return (
        this.rawData.invoice.is_refunded &&
        this.rawData.rent_time_extension_invoice.is_refunded
      )
    },
  },
  methods: {
    getRentalStatusColor() {
      if (this.rawData.status === 'TRIP_COMPLETED') {
        if (this.rawData.trip.status_forced) {
          return 'red'
        } else {
          return 'green'
        }
      } else {
        return this.colors[this.rawData.status] || 'blue'
      }
    },
    getRentalStatusText() {
      if (this.rawData.status === 'TRIP_COMPLETED') {
        if (this.rawData.trip.status_forced) {
          return 'Force End'
        } else {
          return 'Completed'
        }
      } else {
        return this.texts[this.rawData.status] || '--'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import './$rental-status.scss';
</style>
