var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('t-dropdown',{attrs:{"variant":"smActions"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
                  var mousedownHandler = ref.mousedownHandler;
                  var focusHandler = ref.focusHandler;
                  var blurHandler = ref.blurHandler;
                  var keydownHandler = ref.keydownHandler;
return [_c('button',{class:_vm.getRentalStatusClass,staticStyle:{"height":"35px","width":"170px"},attrs:{"aria-label":"Rent Status Dropdown","aria-haspopup":"true"},on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[_c('span',[_vm._v(_vm._s(_vm.getRentalStatusText(_vm.status)))]),(!_vm.isBothRefunded && _vm.rawData.status === 'TRIP_COMPLETED')?_c('i',{staticClass:"fas fa-chevron-down"}):_vm._e()])]}},{key:"default",fn:function(ref){
                  var hide = ref.hide;
return [_c('div',{staticClass:"py-1 mt-1 bg-white rounded-md shadow-md",on:{"click":function($event){return hide()}}},[(
              _vm.rawData.status === 'TRIP_COMPLETED' &&
                _vm.rawData.invoice &&
                !_vm.rawData.invoice.is_refunded
            )?_c('t-dropdown-item',{staticClass:"block px-3 py-1.5 cursor-pointer text-oOrange hover:bg-orange-100",on:{"click":function($event){return _vm.$emit('rental-refund')}}},[_vm._v(" Rental Fee Refund ")]):_vm._e(),(
              _vm.rawData.status === 'TRIP_COMPLETED' &&
                _vm.rawData.rent_time_extension_invoice &&
                !_vm.rawData.rent_time_extension_invoice.is_refunded
            )?_c('t-dropdown-item',{staticClass:"block px-3 py-1.5 cursor-pointer text-oOrange hover:bg-orange-100",on:{"click":function($event){return _vm.$emit('extension-refund')}}},[_vm._v(" Extension Fee Refund ")]):_vm._e()],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }